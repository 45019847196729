<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Alumni Information and News Update</v-card-title>
      <v-card-text v-if="!emailChecked">
        <p>Please enter your email address below to verify access to this record.</p>
        <v-text-field ref="emailRef" v-model="emailCheck" :rules="emailRules" label="Email Address" @keydown="emailKeydown"></v-text-field>
        <v-alert v-if="emailError" style="margin:1em" color="error" icon="fas fa-exclamation-triangle" dark>The email address you entered does not match our records for the given update code. Please make sure you enter the same email as the one to which you received the link to this page.</v-alert>
        <v-btn @click="verifyEmail" style="margin-top:1em">{{ verifyingEmail ? 'Checking...' : 'Verify Email' }}</v-btn>
      </v-card-text>
      <v-card-text v-else-if="isSubmitting">
        <p v-if="isUploading">Uploading your photo. Depending on the size of the file and your internet speed, this could take a minute.</p>
        <p v-else>Processing and saving your form submission data. This should only take a few seconds...</p>
      </v-card-text>
      <v-card-text v-else-if="submitted">
        <p>Thank you for submitting your updated information!</p>
        <p v-if="includedViewUpdate">Thank you for submitting an update to <i>View</i>. Each submission is reviewed and might be edited before publication. If you did not include a photo but would like to, you can send a high-res photo (1MB or larger) to <a href="mailto:alumni@covenant.edu" target="_blank">alumni@covenant.edu</a> to accompany your update or you can reload this page (or click the link in the email again) and add a photo to your submission.</p>
      </v-card-text>
      <portal-form
        v-else
        :info-card="revision.infoCard"
        :info-card-text="revision.infoCardText"
        :sections="revision.sections"
        :section-type="revision.sectionType"
        :init-form-data="initFormData"
        title=""
        @submit="saveSubmission"></portal-form>
    </v-card>
  </v-container>
</template>
<style>
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem
}
</style>
<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api'
import axios from 'axios'
import { dataMap, reverseMap, findDiffs } from '@/components/alumni/dataMap.js'
// import { getDiff } from '../../helpers/diffs'

export default {
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root }) {
    const id = computed(() => root.$route.params['id'])
    const emailCheck = ref('')
    const emailRef = ref(null)
    const emailChecked = ref(false)
    const emailRules = ref([
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ])
    const emailError = ref(false)

    const service = root.$feathers.service('alumni/update')

    function emailKeydown (e) {
      if (e.key === 'Enter') {
        verifyEmail()
      }
    }

    const bannerData = reactive({})
    const initFormData = ref({})
    const verifyingEmail = ref(false)
    function verifyEmail () {
      verifyingEmail.value = true
      if (emailCheck.value !== '' && !emailRef.value.hasError) {
        // Check to make sure email exists within the given document ID
        service.get(id.value, { query: { 'email.email': emailCheck.value.toLowerCase() } }).catch((e) => {
          emailError.value = true
        }).then((data) => {
          if (data == null) {
            emailError.value = true
            return
          }
          initFormData.value = dataMap(data)
          for (let l in data) {
            if (l === '_id' || l === 'infoUpdate' || l === 'viewUpdate' || l === 'updatedAt' || l === 'createdAt') continue
            if (typeof data[l] === 'object') {
              if (Array.isArray(data[l])) {
                bannerData[l] = Object.assign([], data[l])
              } else {
                bannerData[l] = Object.assign({}, data[l])
              }
            } else {
              bannerData[l] = data[l]
            }
          }
          if ('infoUpdate' in data && 'formData' in data.infoUpdate) {
            initFormData.value = { ...data, ...data.infoUpdate.formData }
            delete initFormData.value.infoUpdate
          }
          if ('viewUpdate' in data && 'update' in data.viewUpdate) {
            initFormData.value.viewUpdate = data.viewUpdate.update
            initFormData.value.includeUpdate = true
          }
          emailError.value = ''
          emailChecked.value = true
        }).finally(() => {
          verifyingEmail.value = false
        })
      }
    }

    const revision = ref({})
    onMounted(() => {
      root.$feathers.service('forms/base').get('5eb95c6f1a7336683862e49f').then((data) => {
        root.$feathers.service('forms/revision').get(data.revisions.active).then((data) => {
          revision.value = data
        })
      })
    })

    const isUploading = ref(false)
    async function uploadFile (name, file) {
      isUploading.value = true
      let formData = new FormData()
      let dt = new Date()
      formData.append('filename', bannerData.bannerId + ' - ' + name + ' - ' + dt.toISOString().substring(0, 10))
      formData.append('file', file)
      formData.append('contentType', file.type)
      formData.append('folder', '1xIAszyvf-9d5EOo4gtlw8YUmZHqf06Wq')
      try {
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        let res = await axios.post(basePath + '/service/drive-upload', formData)
        console.log(res)
        return res.data.id
      } catch (e) {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error uploading image: ' + e }
        root.$store.dispatch('main/snackbar', snackbar)
        isUploading.value = false
      }
    }

    // async function getFileContents (file) {
    //   console.log(typeof (file))
    //   console.log(file)
    //   const reader = new FileReader()
    //   return new Promise((resolve, reject) => {
    //     reader.onerror = () => {
    //       reader.abort()
    //       reject(reader.error)
    //     }
    //     reader.onload = () => {
    //       resolve(reader.result)
    //     }
    //     reader.readAsDataURL(file)
    //   })
    // }

    const includedViewUpdate = ref(false)
    const submitted = ref(false)
    const isSubmitting = ref(false)
    async function saveSubmission (formData) {
      const name = formData['First Name'] + ' ' + formData['Last Name'] + ' ' + formData['Graduation Year']
      const data = findDiffs(bannerData, reverseMap(formData))
      const dataArr = []
      for (let field in formData) {
        let value = formData[field]
        if (field === 'New Field' || field === 'Photo to include with update (optional)' || value == null) continue
        if (Array.isArray(value) && typeof (value[0]) === 'object') {
          value = value.map((obj) => {
            let arr = []
            for (let field in obj) {
              arr.push({ field, value: obj[field] })
            }
            return arr
          })
        }
        dataArr.push({ field, value })
      }
      isSubmitting.value = true
      // The View updates (if they included one) will be pushed onto the viewUpdates array
      //    (this is the list of pending View updates)
      if (formData['You may include an update about me in the View magazine']) {
        const photo = await uploadFile(name, formData['Photo to include with update (optional)'])
        const update = formData['My View update']
        // Save the viewUpdate to the server, pushing it onto the updates array for the alumni object
        data.viewUpdate = {
          status: 'Pending',
          submitted: new Date(),
          update,
          photo
        }
        if (formData['You may also include this update in the alumni news section of the alumni office\'s monthly e-newsletter']) {
          data.newsletterUpdate = {
            status: 'Pending',
            submitted: new Date(),
            update
          }
        }
        includedViewUpdate.value = true
      }
      let obj = {
        name,
        form: { // The details of the form for which this submission was made; the name is here in addition to the Forms-Base to make it simpler to load for the form submission history
          name: 'Alumni Information and News Update',
          base: '5eb95c6f1a7336683862e49f',
          revision: revision.value._id
        },
        status: 'Submitted',
        submittedDate: new Date(),
        data, // Will be used to store the view update and newsletter update data
        dataArr
      }

      // Save the data
      try {
        const { _id } = await root.$feathers.service('forms/submission').create(obj)
        await service.patch(id.value, { $push: { submissions: _id } })
      } catch (e) {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error saving your update: ' + e }
        root.$store.dispatch('main/snackbar', snackbar)
        isSubmitting.value = false
        return
      }
      isSubmitting.value = false
      submitted.value = true
    }

    return {
      id,
      emailCheck,
      emailRef,
      emailChecked,
      emailRules,
      emailError,
      emailKeydown,
      verifyEmail,
      initFormData,
      verifyingEmail,
      revision,
      // updatedData,
      // fieldUpdated,
      isUploading,
      uploadFile,
      includedViewUpdate,
      submitted,
      isSubmitting,
      saveSubmission
    }
  }
}
</script>
